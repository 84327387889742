/* Sidebar.css */
.sidebar {
    width: 250px; /* Fixed width for the sidebar */
    background-color: #333;
    padding: 20px;
    color: white;
    position: relative; /* Ensures it stays in its place */
    height: 100vh; /* Sidebar takes full height */
  }
  
  .content-area {
    flex: 1;
    padding: 20px;
    background-color: white;
    overflow-y: auto;
  }
  