.size{
    width: 420px;
    height: 550px;
}
.custom-select option:hover {
    background-color: #D0EFFF; /* Hover background for options */
  }
  

  .dropdown {
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .read-more {
    color: #007bff;
    cursor: pointer;
    padding: 8px;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  